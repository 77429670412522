/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { filterNumber, numberFormat, pmt } from "./util"
import { defaultValues } from "./config"
import { Button, Form, Container, FormControl, Row, InputGroup, Col, Dropdown} from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "@StarberryUtils";
import {
  BAC_PAGE_URL
   } from "@Components/common/site/constants";
import $ from "jquery";
import {calculateMonthlyPayment} from "./mortage"
// use project specific import here

// User project specific const
const durationOptions = [10, 15, 20, 25, 30, 35]

// DO NOT DO ANY CHNAGES - START
const MortgageCalc = props => {
  const {
    propertyPrice,
    depositAmount,
    loadDuration,
    interestRate,
    currency,
    defaultResult,
    pricePrefix,
    // instantResult,
    // durationSuffix,
    // yearSuffix,
    // yearSelect,
  } = props

  const prefix = ""
  const [validated, setValidated] = useState(false)
  const [showerror, setShowerror] = useState(false)
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [purchasePrice, setPurchasePrice] = useState(
    prefix + numberFormat(propertyPrice)
  )
  const [deposit, setDeposit] = useState(prefix + numberFormat(depositAmount))
  const [duration, setDuration] = useState(loadDuration)
  const [interest, setInterest] = useState(interestRate)
  const [loan, setLoan] = useState("")
  const [result, setResult] = useState("")
  const [depositError, setDepError] = useState(false)
  const handlePrice = event => {
    let val = filterNumber(event.target.value)
    if(event.target.value !== "£" && event.target.value !== "£0" && event.target.value !== "0" && event.target.value !== ""){
      // if(parseInt(filterNumber(deposit)) > parseInt(filterNumber(event.target.value))){
      //   setMonthlyPayment(0)
      // }
      // else {
    setPurchasePrice(prefix + numberFormat(val))
    if (parseInt(filterNumber(deposit)) && parseInt(val)) {
      let loan = parseInt(val) - parseInt(filterNumber(deposit))
      setLoan(prefix + numberFormat(loan))
    } else {
      setLoan(prefix + 0)
    }
    $('.btn-calculate').click(); 
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(event.target.value)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        duration
      )
    );
    setShowerror(false)
      // }
    }
    else {
      setPurchasePrice('')
      setLoan()
      setMonthlyPayment(0)
    }
  }

  const handleDeposit = event => {
    let d = filterNumber(event.target.value)
    if(event.target.value !== '£' && event.target.value !== "£0" && event.target.value !== '0' && event.target.value !== ""){
      // if(parseInt(filterNumber(purchasePrice)) === parseInt(filterNumber(event.target.value)) || parseInt(filterNumber(purchasePrice)) < parseInt(filterNumber(event.target.value))){
      //   setShowerror(true)
      //   setDeposit(prefix + numberFormat(d))        
      // }
      // else {
    setDeposit(prefix + numberFormat(d))

    if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
      let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
      setLoan(prefix + numberFormat(loan2))
    } else {
      setLoan(prefix + 0)
    }
    $('.btn-calculate').click(); 
    var par = purchasePrice.replace('£', '').replace(/,/g, '')
    var dep = event.target.value.replace('£', '').replace(/,/g, '')
    var par1 = parseInt(par)
    var dep1 = parseInt(dep)
    if(par1 < dep1 || par1 === dep1){
      setDepError(true)
      setMonthlyPayment(0)
    }
    else {
      setDepError(false)
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(event.target.value)),
        duration
      )
    );
    
      }
    setShowerror(false)
    
      // }
  }
  else {
    setLoan('')
    setDeposit('')
    setMonthlyPayment(0)
  }

  }

  const handleDuration = event => {
    setDuration(filterNumber(event.target.value))
    $('.btn-calculate').click(); 
    setMonthlyPayment(
      calculateMonthlyPayment(
        parseInt(filterNumber(purchasePrice)),
        parseFloat(interest),
        parseInt(filterNumber(deposit)),
        event.target.value
      )
    );
  }

  const handleInterest = event => {

    setInterest(event.target.value.replace(/[^\d.]/g, ""))
    if(event.target.value !== '0' && event.target.value !== ""){
      $('.btn-calculate').click(); 
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(purchasePrice)),
          parseFloat(event.target.value),
          parseInt(filterNumber(deposit)),
          duration
        )
      );
      setShowerror(false)
    }
    else {
      setShowerror(true)
    }
  }

  const handleLoan = event => {
    setLoan(prefix + numberFormat(filterNumber(event.target.value)))
    $('.btn-calculate').click(); 
  }

  const getResult = (interest, duration, loan) => {
    let result = -pmt(
      interest / 100 / 12,
      filterNumber(duration) * 12,
      filterNumber(loan),
      0,
      1
    )
    setResult(numberFormat(Math.round(result)))
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (form.checkValidity() === false) {
      setShowerror(true)
      setValidated(true)
    } else {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
        getResult(interest, duration, loan)
      }
    }
  }

  useEffect(() => {
    $('.btn-calculate').click();    
    if (defaultResult) {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
      }
      getResult(interest, duration, loan)
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(purchasePrice)),
          parseFloat(interest),
          parseInt(filterNumber(deposit)),
          duration
        )
      );

    }
  }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])

  useEffect(() => {
      if (
        parseInt(filterNumber(purchasePrice)) &&
        parseInt(filterNumber(deposit))
      ) {
        let loan =
          parseInt(filterNumber(purchasePrice)) -
          parseInt(filterNumber(deposit))
        setLoan(prefix + numberFormat(loan))
      }
      getResult(interest, duration, loan)
      setMonthlyPayment(
        calculateMonthlyPayment(
          parseInt(filterNumber(purchasePrice)),
          parseFloat(interest),
          parseInt(filterNumber(deposit)),
          duration
        )
      );
  }, [])
  // DO NOT DO ANY CHNAGES - END
  var monthly_payment = Math.round(monthlyPayment);
  return (
    <section className={`${props.class} calculator-section`}>
    <Container>
    <Row>
  <Col lg={5} xl={3}>
  <ScrollAnimation  animateOnce={true} animateIn="fadeInUp">
  <h2>Mortgage Calculator</h2>
  <p>Calculate your monthly repayment using our handy Mortgage Calculator Tool.</p>
  <Link to={BAC_PAGE_URL.alias} className="btn btn-outline d-none d-lg-inline-flex">Book a Consultation</Link>
  </ScrollAnimation>
  </Col>

  <Col lg={7} xl={9}>
  <ScrollAnimation animateOnce={true} animateIn="fadeIn"> 

    <div className="calculator-form form mortgate-form">
      {showerror && (
        <div className="alert-error">
          <p>Highlighted fields are required | invalid</p>
        </div>
      )}
      <Form
        name="MortgageCalc"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="bot-field" />
        <Row>
        <Col md={6}>
        <Form.Group controlId="purchase_price">
          <Form.Label>Property Value</Form.Label>
          <div className="addonrow">
          <span className="input-addon-left">&#163;</span>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="purchase_price"
            value={purchasePrice}
            placeholder="Purchase Price"
            onChange={handlePrice}
            autocomplete="off"
          />
          <span className="input-addon-right d-none"></span>
          </div>
        </Form.Group>
        </Col>
        <Col md={6}>    
        <Form.Group controlId="deposit">
          <Form.Label>Deposit</Form.Label>
          <div className="addonrow">
          <span className="input-addon-left">&#163;</span>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="deposit_available"
            value={deposit}
            autocomplete="off"
            placeholder="Deposit Available"
            onChange={handleDeposit}
          />
          <span className="input-addon-right d-none"></span>
        </div>
        </Form.Group>

        {depositError ? <div className="error deper">
                        Deposit Amount is greater than the Property Price amount 
                        </div> : null}
        </Col>
        
        </Row>

        <Row>
        <Col md={6}>    
        <Form.Group controlId="duration">
          <Form.Label>Duration</Form.Label>
          <Form.Control
            as="select"
            className="form-control"
            required
            name="duration"
            value={duration}
            autocomplete="off"
            placeholder="Duration (Years)"
            onChange={handleDuration}
          >
            {durationOptions.map((value, key) => {
              return (
                <option value={value} key={key}>
                  {value} Years
                </option>
              )
            })}
          </Form.Control>
        </Form.Group>
        </Col>
        <Col md={6}>    
        <Form.Group controlId="interest_rate">
          <Form.Label>Interest Rate</Form.Label>
          <div className="addonrow">
          <span className="input-addon-left d-none">&#163;</span>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="interest_rate"
            value={interest}
            placeholder="Interest Rate (%)"
            onChange={handleInterest}
            autocomplete="off"
          />
          <span className="input-addon-right">%</span>
          </div>
          {interest === "0" ? 
                         <div className="error">
                           Please enter above Zero %
                         </div>
                         : null}
        </Form.Group>
        </Col>

        </Row>

        <Form.Group controlId="loan_amount" className="d-none">
          <Form.Label>Loan Amount</Form.Label>
          <Form.Control
            className="form-control"
            required
            type="text"
            name="loan_amount"
            value={loan}
            placeholder="Loan Amount"
            autocomplete="off"
            onChange={handleLoan}
            disabled="disabled"
          />
        </Form.Group>
        <Button type="submit" className="btn-calculate btn btn-pink">
          Calculate
        </Button>
      </Form>
      {!showerror && result && (
        <div className="alert-success calc-success">
          <Row className="form-footer">
              <Col md={12}>
              <Form.Label>Monthly Repayment</Form.Label>
              <div className="amount">
                  <span className="main-amount">{currency} {numberFormat(monthly_payment)}</span>
                  <span className="main-amount-side-text">per month</span>
              </div>
              </Col>
          </Row>
        </div>
      )}
    </div>
  </ScrollAnimation>

    </Col>


    </Row>
    </Container>
    </section>
  )
}

MortgageCalc.defaultProps = {
  propertyPrice: defaultValues.PROPERTY_VALUE,
  depositAmount: defaultValues.DEPOSIT,
  loadDuration: defaultValues.DURATION,
  interestRate: defaultValues.INTEREST,
  currency: process.env.CURRENCY
    ? process.env.CURRENCY
    : defaultValues.CURRENCY,
  defaultResult: defaultValues.DEFAULT_RESULT,
  pricePrefix: defaultValues.PRICE_PREFIX,
  // instantResult: defaultValues.INSTANT_RESULT,
  // durationSuffix: defaultValues.DURATION_SUFFIX,
  // yearSuffix: defaultValues.YEAR_SUFFIX,
  // yearSelect: defaultValues.YEAR_SELECT,
}

MortgageCalc.propTypes = {
  propertyPrice: PropTypes.any,
  depositAmount: PropTypes.any,
  loadDuration: PropTypes.any,
  interestRate: PropTypes.any,
  currency: PropTypes.string,
  defaultResult: PropTypes.any,
  pricePrefix: PropTypes.any,
  // instantResult: PropTypes.any,
  // durationSuffix: PropTypes.any,
  // yearSuffix: PropTypes.any,
  // yearSelect: PropTypes.any
}

export default MortgageCalc
