import React from "react"
import { Link } from "@StarberryUtils"


import SEO from "@Components/SEO/seo"
import Layout from "@Components/Layout/layout"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="error-page">
	    <h1>Ooops!</h1>
	    <p>Sorry, the page you're after seems to be missing.</p>
	    <Link to="/" className="btn btn-primary">Return Home</Link>
	</div>
  </Layout>
)

export default NotFoundPage
