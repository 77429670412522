import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Form, Container, FormControl, Row, InputGroup, Col } from "react-bootstrap";
import "./HomeBookConsultation.scss";
import Modal from "react-bootstrap/Modal"
import BookAViewing from "@Components/BookAViewing/book-a-viewing.js"
import CustomButton from "@Components/calendly/book-a-video-call"
import ModelForm from "@Components/ModelForm/ModelForm";
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform";
import ReactMarkdown from "react-markdown"
import GetURL from '@Components/common/site/get-url';
import { useQuery } from '@apollo/client';
import { Phoneicon } from '@Components/icon/icon'
import gql from "graphql-tag";

import { inViewOptions } from '../../../Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import {ImageModule} from "@Components/common/Image_Module";

export const uknumberformat = (x) => {
    if (!x) return x; 
    const currentValue = x.toString().replace(/[^\d]/g, '');
    return `${currentValue.slice(0, 5)} ${currentValue.slice(5, 11)}`; 
}
const containerVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.4,
            ease: [0.33, 1, 0.68, 1],
            duration: 0.4
        }
    }
}

const GET_ALL_TEAMS = gql`
  query GetTeam{
    teams(where: {Publish:true}, sort: "createdAt:asc") {
      id
      imagetransforms
      ggfx_results {
        id
        content_type
        transforms
        src_import_url
        src_cftle
        field
      }
      Repeat_Id
      Name
      Designation
      Email
      Phone
      Book_A_Video
      Image {
        alternativeText
        url
      }
    }
  }
`
const PropertyBookConsultation = (props) => {
    const [property_rezise_images, setPropertyReziseImages] = useState("")
    const [negoname, setNegoname] = useState("")
    const [negophone, setNegophone] = useState("")
    const [negoemail, setNegoemail] = useState("")
    const [negodes, setNegodes] = useState("")
    const [negobook, setNegobook] = useState("")
    const [negoid, setNegoid] = useState("")
    const [negoimagetransforms, setNegoimagetransforms] = useState("")
    const { data } = useQuery(GET_ALL_TEAMS)
    useEffect(() => {
        setNegophone(props.data?.crm_negotiator_id?.Telephone?.value)
        setNegoname(props.data?.crm_negotiator_id?.Name?.value)
        setNegoemail(props.data?.crm_negotiator_id?.Email?.value)
        if (data && data.teams && data.teams.length > 0 && props.data?.crm_negotiator_id?.ID) {
            for (var i = 0; i < data.teams.length; i++) {
                if (props.data?.crm_negotiator_id?.Email?.value === data.teams[i].Email) {
                    setPropertyReziseImages(data.teams[i].Image)
                    setNegoname(data.teams[i].Name)
                    setNegophone(data.teams[i].Phone)
                    setNegoemail(data.teams[i].Email)
                    setNegodes(data.teams[i].Designation)
                    setNegobook(data.teams[i].Book_A_Video)
                    setNegoid(data.teams[i].id)
                    setNegoimagetransforms(data.teams[i].imagetransforms)


                }
                else if (props.data?.crm_negotiator_id?.Name?.value === data.teams[i].Name) {
                    setPropertyReziseImages(data.teams[i].Image)
                    setNegoname(data.teams[i].Name)
                    setNegophone(data.teams[i].Phone)
                    setNegoemail(data.teams[i].Email)
                    setNegodes(data.teams[i].Designation)
                    setNegobook(data.teams[i].Book_A_Video)
                    setNegoid(data.teams[i].id)
                    setNegoimagetransforms(data.teams[i].imagetransforms)


                } else if (props.data?.crm_negotiator_id?.ID?.value === data.teams[i].Repeat_Id) {
                    setPropertyReziseImages(data.teams[i].Image)
                    setNegoname(data.teams[i].Name)
                    setNegophone(data.teams[i].Phone)
                    setNegoemail(data.teams[i].Email)
                    setNegodes(data.teams[i].Designation)
                    setNegobook(data.teams[i].Book_A_Video)
                    setNegoid(data.teams[i].id)
                    setNegoimagetransforms(data.teams[i].imagetransforms)

                }
            }
        }
    }, [data])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (event) => {
        setShow(true);
    }
    let processedImages = JSON.stringify({});
    if (negoimagetransforms?.Image_Transforms) {
        processedImages = negoimagetransforms.Image_Transforms;
    }

    return (
        <InView {...inViewOptions}>
            {({ ref, inView }) => {
                return (
                    <motion.section
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerVariants}
                    >
                        <React.Fragment>
                            <section className="home-book-consultation">
                                <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                                    <Modal
                                        id="share_modal_mobile"
                                        show={show}
                                        onHide={handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                        size="lg"
                                        dialogClassName="modal-90w modal-form"
                                    >
                                        <Modal.Header closeButton className="contact-close-btn memberPopup">
                                            <Modal.Title className="w-100"><h6 className="text-center content-primary-color mb-0 text-uppercase">Request a viewing</h6></Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <BookAViewing price={props.price} image={props.image} officeNumber={props.officeNumber} handleClose={handleClose} search_type={props.search_type} url={props.pageurl} address={props.data?.display_address} />

                                        </Modal.Body>
                                    </Modal>
                                    <Container>
                                        <Row>
                                            <Col lg={7}>
                                                <div className="block-head">
                                                    <ScrollAnimation animateOnce={true} animateIn='fadeIn'><h2>Enquire about this property.</h2></ScrollAnimation>
                                                    <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                                        <ReactMarkdown source={'<p>Book an appointment to view the property or get in touch with one of our team.</p>'} allowDangerousHtml />
                                                    </ScrollAnimation>
                                                </div>
                                                <div className="btn-group-block btn-bg-dark d-none d-lg-flex">
                                                    <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                                        <a href="javascript:void(0)" onClick={handleShow} className={"btn btn-primary-bg"}>Request a viewing</a>
                                                    </ScrollAnimation>
                                                    {
                              props.search_type === "lettings" ?
                              
                              <a href={'tel:' + '01865 722722'} class={"btn btn-outline-bg"} ><i class="icon-phone" /> <i class="icon-phone-menu"></i> Call {uknumberformat('01865 722722')}</a>
                              :
                              negophone ?
                                                        <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                                            <a href={'tel:' + negophone} class={"btn btn-outline-bg"} ><i class="icon-phone" /> <i class="icon-phone-menu"></i> Call {uknumberformat(negophone)}</a>
                                                        </ScrollAnimation>
                                                        : null}

                                                </div>
                                            </Col>
                                            {property_rezise_images &&
                                                <Col lg={5} className=" d-none d-lg-block">
                                                    <div className="person-detail person2">
                                                        <div className="person-info">
                                                            <div className="person-img">
                                                                <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                                                    {/* <picture>
                                        <source media="(min-width:992px)" srcSet={PersonImgDesktop2} />
                                        <source media="(min-width:768px)" srcSet={PersonImgTablet2} />
                                        <img src={PersonImgMobile2} alt="" />
                                    </picture> */}
                                                                    <ImageModule ImageSrc={property_rezise_images} attr={{ alt: negoname }} ggfx_results={props.data.ggfx_results} imagename="team.Image.choose" strapi_id={negoid} />

                                                                    {/* <ImageTransform imagesources={property_rezise_images} renderer="srcSet" imagename='team.Image.choose'
                                                                        attr={{ alt: "", className: '' }}
                                                                        imagetransformresult={processedImages} id={negoid} ></ImageTransform> */}
                                                                </ScrollAnimation>
                                                            </div>
                                                            <div className="person-content">
                                                                {negoname ? <ScrollAnimation animateOnce={true} animateIn='fadeIn'><p className="name">{negoname}</p></ScrollAnimation> : null}
                                                                {negodes ? <ScrollAnimation animateOnce={true} animateIn='fadeIn'><p className="designation">{negodes}</p></ScrollAnimation> : null}
                                                                <ScrollAnimation animateOnce={true} animateIn='fadeIn'><p className="person-links">{negoemail ? <ModelForm page="" Name={negoname} mailid={negoemail} /> : null}  {negobook ? <> / <CustomButton url={negobook} /> </> : ""}</p></ScrollAnimation>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                        {property_rezise_images &&
                                            <Row className="d-lg-none d-none">
                                                <Col lg={12}>
                                                    <div className="person-detail person2">
                                                        <div className="person-info">
                                                            <div className="person-img">
                                                                <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                                                    {/* <picture>
                                        <source media="(min-width:992px)" srcSet={PersonImgDesktop2} />
                                        <source media="(min-width:768px)" srcSet={PersonImgTablet2} />
                                        <img src={PersonImgMobile2} alt="" />
                                    </picture> */}                  
                                                                    <ImageModule ImageSrc={property_rezise_images} attr={{ alt: negoname }} ggfx_results={props.data.ggfx_results} imagename="team.Image.choose" strapi_id={negoid} />

                                                                    {/* <ImageTransform imagesources={property_rezise_images} renderer="srcSet" imagename='team.Image.choose'
                                                                        attr={{ alt: "", className: '' }}
                                                                        imagetransformresult={processedImages} id={negoid} ></ImageTransform> */}
                                                                </ScrollAnimation>
                                                            </div>
                                                            <div className="person-content">
                                                                {negoname ? <ScrollAnimation animateOnce={true} animateIn='fadeIn'><p className="name">{negoname}</p></ScrollAnimation> : null}
                                                                {negodes ? <ScrollAnimation animateOnce={true} animateIn='fadeIn'><p className="designation">{negodes}</p></ScrollAnimation> : null}
                                                                <ScrollAnimation animateOnce={true} animateIn='fadeIn'><p className="person-links">{negoemail ? <ModelForm page="" Name={negoname} mailid={negoemail} /> : null}  {negobook ? <> / <CustomButton url={negobook} /> </> : ""}</p></ScrollAnimation>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                        <Row className="d-lg-none">
                                            <Col lg={5}>
                                                <div className="btn-group-block btn-bg-dark ">
                                                    <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                                        <a href="javascript:void(0)" onClick={handleShow} className={"btn btn-primary-bg"}>Request a viewing</a>
                                                    </ScrollAnimation>
                                                    {
                              props.search_type === "lettings" ?
                              
                              
                              <a href={'tel:' + '01865 722722'} class={"btn btn-outline-bg"} >Call {uknumberformat('01865 722722')}</a>
                              :

                                                    negophone ?
                                                        <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                                                            <a href={'tel:' + negophone} class={"btn btn-outline-bg"} >Call {uknumberformat(negophone)}</a>
                                                        </ScrollAnimation>
                                                        : null}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </ScrollAnimation>
                            </section>
                        </React.Fragment>
                    </motion.section>
                )
            }}
        </InView>
    )
}

export default PropertyBookConsultation;