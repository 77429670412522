import React, { useState } from 'react'
import Modal from "react-bootstrap/Modal"
import GeneralEnquiry from "../../Components/forms/general-enquiryform"
import Email from "../../images/email.svg"
const ModelForm = (props) => {
    const [show, setShow] = useState(false);
    const handleOpen = () => setShow(true);
    const handleClose = () => setShow(false);

    // console.log ("xxxxxxxxxxxxx", props.Name)

    return (
    <>
    {props.page ==="teamdetail" ? 
    <a onClick={handleOpen} href="javascript:void(0)"> <img src={Email}/> {props.mailid}</a> : 
    props.page === "search-result" ?
    <span className={props.ContactNumber ? "email icon-round-box": 'email icon-round-box'}>
                <a onClick={handleOpen} href="javascript:void(0)">
                  <span className="icon-wrap">
                    <i className="icon-envelope-small"></i>
                  </span>{" "}
                  <span className="icon-text d-none d-md-block">Contact</span>
                </a>
              </span> :
    <a onClick={handleOpen} href="javascript:void(0)" className="btn-text-link">Email</a> }
    <Modal
        id="share_modal_mobile"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
          dialogClassName="modal-90w modal-form"
      >
        <Modal.Header closeButton className="contact-close-btn memberPopup">
          <Modal.Title className="w-100"><h6 className="text-center content-primary-color mb-0">Email to {props?.Name}</h6></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <GeneralEnquiry Office={"person"} mailId={props?.mailid}  />

        </Modal.Body>
      </Modal>  
      </>
    )
}

export default ModelForm