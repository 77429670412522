import React from "react"
import { openPopupWidget } from 'react-calendly';
import { Button } from 'react-bootstrap';

 
const CustomButton = ({ url, prefill, pageSettings, utm }) => {

  const onClick = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'book_a_video_call',
      'formType': 'bookAVideo-icon',
      'formId': 'bookAVideoID',
      'formName': 'bookAVideo-link-footer',
      'formLabel': 'bookAVideo-link-footer'
    });
    openPopupWidget({ url, prefill, pageSettings, utm });
  } 
 
  return <a onClick={onClick} href="javascript:void(0)" className="btn-text-link">Book a Video Call</a>
}

export default CustomButton;
